<template>
  <div>
    <TopBanner
      title="元宇宙工厂"
      :bgImg="require('@/assets/product/MetaverseFactory/banner-bg.png')"
    >
      用端到端的数字化集成技术，去除一切中间环节，实现C2M+OTD的自适应运营。
    </TopBanner>
    <TopNav :list="nav" />
    <ViewMain
      title="元宇宙工厂"
      theme="gray"
      :id="getNavHash('数字孪生')"
    >
      <ViewContent title="产品介绍">
        结合G-iPS数字孪生孪生软件和游戏引擎技术, 可将工程中已经建模的产线模型和布局一键导入到元宇宙工厂环境中带来新的体验和交互。借助英伟达元宇宙平台Omniverse提供的强大算力可实现物理级逼真的画面和效果。元宇宙工厂将现实工业环境中的研发设计、生产制造、营销销售、售后服务等环节和场景在虚拟空间实现全面部署。
        <div style="text-align: center; margin-top: 40px;">
          <img src="@/assets/product/MetaverseFactory/pic-1.png" alt="" />
        </div>
      </ViewContent>
      <ViewContent title="产品功能">
        虚实同步：虚拟制造，耦合同步、实时监控：运行监测，及时干预、逆向控制：实时干涉，协同设计、数据可视化：聚合数据，洞见未来、大屏数显：数字分析，智能制造、移动端操作：人机协同，远程运维、XR模式： 混合现实，体验升级、多端用户: 多人在线。
      </ViewContent>
      <ViewContent title="产品价值">
        <OrderedList
          :list="[
            {
              content: '可将数字孪生软件内使用的CAD模型和产线布局快速导入元宇宙场景中,避免重复建模,缩短项目周期；',
            },
            {
              content: '元宇宙工厂中项目团队可以将从构思和概念设计到工厂车间的生产自动化流程的每个阶段连接到一起并且在AI的支持下高效智能运行；',
            },
            {
              content: '元宇宙工厂可以在移动端和XR端高效运行并提供远程体验,突破空间和时间的约束；',
            },
            {
              content: '通过混合现实设备,支持远程专家指导会议。缩短问题解决时间，节约差旅成本；',
            },
          ]"
        />
      </ViewContent>
    </ViewMain>
    <ViewMain
      title="元宇宙工厂"
      theme="gray"
      :id="getNavHash('未来工厂')"
    />
  </div>
</template>

<script>
import ViewMain from '@/components/ViewMain'
import ViewContent from '@/components/ViewContent'
import TopBanner from '@/components/TopBanner'
import TopNav from '@/components/TopNav'
import OrderedList from '@/components/OrderedList'
import mixin from '@/views/product/mixin'
import nav from './nav'

export default {
  components: {
    ViewMain,
    ViewContent,
    TopBanner,
    TopNav,
    OrderedList,
  },
  mixins: [mixin],
  data () {
    return {
      nav,
    }
  },
}
</script>

<style lang="less" scoped>
</style>
