export default [
  {
    name: '数字孪生',
    hash: 'metaverse',
  },
  {
    name: '未来工厂',
    hash: 'metaverse',
  },
]
