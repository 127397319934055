export default [
  {
    name: 'PMS自适应主数据管理',
    hash: 'imom-pms',
  },
  {
    name: 'JIS自适应生产管理',
    hash: 'imom-jis',
  },
  {
    name: 'LES自适应物流管理',
    hash: 'imom-les',
  },
]
