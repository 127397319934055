var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('TopBanner',{attrs:{"title":"应用系统","bgImg":require('@/assets/product/appSystem/banner-bg.png')}},[_vm._v(" 基于物联平台强大的设备数据融合能力，深挖企业数字化转型在各个业务领域的痛点，打造智能仓储、设备管理、能耗管理、实验室管理等方向的针对性解决方案，实现企业对生产运营的全流程透明化管理，为企业数字化转型提供新基座。 ")]),_c('TopNav',{attrs:{"list":_vm.nav}}),_c('ViewMain',{attrs:{"title":"G-iWCS智能仓储控制系统","theme":"gray","id":_vm.getNavHash('G-iWCS智能仓储控制系统')}},[_c('ViewContent',{attrs:{"title":"产品介绍"}},[_vm._v(" G-iWCS通过物联网实现设备的交互控制，根据仓库管理系统下达任务控制物料上架、下架、移位，整个过程完全自动化，同时，可以根据商品属性（如保质期、分类等）进行自动倒库处理，以保证其效率最大化。 "),_c('div',{staticStyle:{"margin-top":"40px","text-align":"center"}},[_c('img',{attrs:{"src":require("@/assets/product/appSystem/pic-1.png"),"alt":""}})])]),_c('ViewContent',{attrs:{"title":"产品功能"}},[_vm._v(" 智能入库、智能出库、标准分类管理、手动急停、库存分析、设备实时监控、可视化货位、日志管理。 ")]),_c('ViewContent',{attrs:{"title":"产品价值"}},[_c('OrderedList',{attrs:{"list":[
          {
            content:
              '为企业提高了仓库管理效率，标准化管理使得库存清晰可视化，降低了管理成本，减少人力、财力的浪费；',
          },
          {
            content: '标准化、自动化管理也为企业提供了订单时效性的保证。',
          },
        ]}})],1)],1),_c('ViewMain',{attrs:{"title":"G-iEAM设备管理系统","id":_vm.getNavHash('G-iEAM设备管理系统')}},[_c('ViewContent',{attrs:{"title":"产品介绍"}},[_vm._v(" G-iEAM 实现设备管理科学化，以全生命周期为主线，同时引入物联技术实现设备状态的实时监控与故障预警，帮助企业降低设备故障率，保持设备稳定性，实现企业资产效益的全面提升。 "),_c('div',{staticStyle:{"margin-top":"40px","text-align":"center"}},[_c('img',{attrs:{"src":require("@/assets/product/appSystem/pic-2.png"),"alt":""}})])]),_c('ViewContent',{attrs:{"title":"产品功能"}},[_vm._v(" 设备工单管理、设备资产管理、设备维修管理、设备维护管理、备件管理、BI报表分析。 ")]),_c('ViewContent',{attrs:{"title":"产品价值"}},[_c('OrderedList',{attrs:{"list":[
          {
            content:
              '预防性维护：降低设备故障率，提高设备完好率，提高设备可用性，延长设备使用寿命；',
          },
          {
            content:
              '维修单流转：提升维修效率，节省人工成本，提高设备可用性；',
          },
          {
            content:
              '预测性维护：降低备件消耗，降低维保成本，提高备件使用率，降低备件闲置率；',
          },
          {
            content:
              '知识库检索：节省人工成本，增强知识传承，降低设备故障时间。',
          },
        ]}})],1)],1),_c('ViewMain',{attrs:{"title":"G-iEMS能耗管理系统","theme":"gray","id":_vm.getNavHash('G-iEMS能耗管理系统')}},[_c('ViewContent',{attrs:{"title":"产品介绍"}},[_vm._v(" G-iEMS能耗管理系统采用分层分布式系统体系结构，对电力、燃气、水、热等各分类能耗数据进行采集、处理，并分析能耗状况，实现节能应用。 "),_c('div',{staticStyle:{"margin-top":"40px","text-align":"center"}},[_c('img',{attrs:{"src":require("@/assets/product/appSystem/pic-3.png"),"alt":""}})])]),_c('ViewContent',{attrs:{"title":"产品功能"}},[_vm._v(" 能源监管、安全告警、精细化管控、可视化展示、能效分析、用能优化、碳排放管理、辅助决策。 ")]),_c('ViewContent',{attrs:{"title":"产品价值"}},[_c('OrderedList',{attrs:{"list":[
          {
            content:
              '为企业优化能源管理效率，分析并提供能源改善的策略，切实降低能源成本；',
          },
          {
            content:
              '是实现不断改进企业能源管理水平、持续提高企业能源利用率的有效手段。',
          },
        ]}})],1)],1),_c('ViewMain',{attrs:{"title":"G-iLIMS实验室管理系统","id":_vm.getNavHash('G-iLIMS实验室管理系统')}},[_c('ViewContent',{attrs:{"title":"产品介绍"}},[_vm._v(" G-iLIMS是一套检验综合管理和产品质量监控体系，针对实验室的整套环境而设计，是实现实验室人、机、料、法、环全面资源管理的业务系统。 "),_c('div',{staticStyle:{"margin-top":"40px","text-align":"center"}},[_c('img',{attrs:{"src":require("@/assets/product/appSystem/pic-4.png"),"alt":""}})])]),_c('ViewContent',{attrs:{"title":"产品功能"}},[_vm._v(" 订单管理、现场采样、样品管理、检测录入、耗材管理、质控管理、检测报告管理、仪器管理、统计分析、绩效管理。 ")]),_c('ViewContent',{attrs:{"title":"产品价值"}},[_c('OrderedList',{attrs:{"list":[
          {
            content:
              '提高试验执行效率：具备设备集成自动抓取试验数据的能力，节省数据誊抄的时间，以缩短产品研制周期；',
          },
          {
            content:
              '提高试验数据利用率：既可在企业内部实现所有数据的共享，而且还可以把检测结果放到互联网上，以供用户查询；',
          },
          {
            content:
              '提升实验室管理能力：将实验室中不易管理的人、物、料、库等因素有效的进行了流程化管控；',
          },
          {
            content:
              '提升产品综合质量：以固化的数据分析经验，进行快速数据处理，以验证产品功能和性能；',
          },
          {
            content:
              '提供业务拓展数据支撑：通过能力库的搭建，从数据层面可以直观的反应当前实验室的综合检测能力。',
          },
        ]}})],1)],1),_c('ViewMain',{attrs:{"title":"G-iAGV 通用AGV调度系统","theme":"gray","id":_vm.getNavHash('G-iAGV 通用AGV调度系统')}},[_c('ViewContent',{attrs:{"title":"产品介绍"}},[_vm._v(" 制造生产车间的同一区域内有多家厂商AGV小车在运行，且由各自厂商的调度系统独立管理，多AGV小车存在碰撞风险，会影响搬送效率。基于物联网平台建立通用AGV调度系统，协同管理多厂家AGV小车作业。 "),_c('div',{staticStyle:{"margin-top":"40px","text-align":"center"}},[_c('img',{attrs:{"src":require("@/assets/product/appSystem/pic-5.png"),"alt":""}})])]),_c('ViewContent',{attrs:{"title":"产品功能"}},[_vm._v(" • 通过边缘云计算平台建立适配多类型AGV的采集接口，实现对各类AGV小车的数据聚集； "),_c('br'),_vm._v(" • 边缘平台以标准的协议、标准的模型与调度系统进行交互，屏蔽多家厂商AGV之间的差异；"),_c('br'),_vm._v(" • 基于边缘平台建立AGV调度系统，实现一个调度系统协同管理多家AGV。 ")]),_c('ViewContent',{attrs:{"title":"产品价值"}},[_c('OrderedList',{attrs:{"list":[
          {
            content: '实现厂区多AGV小车协同运行，提升搬送效率；',
          },
          {
            content: '规避多AGV小车互相干扰、碰撞风险。',
          },
        ]}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }