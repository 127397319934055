export default [
  {
    name: '软安灯呼叫系统',
    hash: 'vcOne',
  },
  {
    name: '精益提案管理系统',
    hash: 'vcTwo',
  },
  {
    name: 'AR质量防错眼镜',
    hash: 'vcThree',
  },
  {
    name: '工艺诊断专家系统',
    hash: 'vcFour',
  },
]
