<template>
  <div class="orderedList-container">
    <div class="ordered-list">
      <div class="ordered-item" v-for="(item, idx) in list" :key="idx">
        <div class="item-main">
          <div class="item-num">{{ idx + 1 }}</div>
          <div class="item-content">{{ item.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
