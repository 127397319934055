<template>
  <ViewContainer class="view-main" :class="{ 'is-gray': theme === 'gray' }">
    <ViewWrapper>
      <div class="main-title">{{ title }}</div>
      <div class="main-wrapper">
        <slot />
      </div>
    </ViewWrapper>
  </ViewContainer>
</template>

<script>
import ViewContainer from '../ViewContainer'
import ViewWrapper from '../ViewWrapper'

export default {
  components: {
    ViewContainer,
    ViewWrapper,
  },
  props: {
    theme: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
