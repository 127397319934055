<template>
  <ViewContainer
    class="top-banner"
    :style="{ backgroundImage: `url(${bgImg})` }"
  >
    <ViewWrapper>
      <div class="banner-wrapper">
        <div class="banner-title">{{ title }}</div>
        <div class="banner-desc"><slot /></div>
      </div>
    </ViewWrapper>
  </ViewContainer>
</template>

<script>
import ViewContainer from '../ViewContainer'
import ViewWrapper from '../ViewWrapper'

export default {
  components: {
    ViewContainer,
    ViewWrapper,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    bgImg: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
