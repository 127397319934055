export default [
  {
    name: '工业视觉AI',
    hash: 'vcOne',
  },
  {
    name: '工业数据AI',
    hash: 'vcTwo',
  },
]
