<template>
  <div class="list-container">
    <div class="iconLeft-list">
      <div class="iconLeft-item" v-for="(item, idx) in list" :key="idx">
        <div class="item-wrapper">
          <div class="item-icon"><img :src="item.icon" alt="" /></div>
          <div class="item-main">
            <div class="item-title">{{ item.title }}</div>
            <div class="item-desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
