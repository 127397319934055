<template>
  <div class="view-content">
    <div class="content-title">{{ title }}</div>
    <div class="content-wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
