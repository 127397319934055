var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('TopBanner',{attrs:{"title":"工艺培训","bgImg":require('@/assets/product/craftTraining/banner-bg.png')}},[_vm._v(" 在工厂生产作业的场景中，围绕人和质量标准要求，融合AI图像识别和AR展示技术，实现作业流程动态指导、作业质量实时检测、作业数据精细化分析等功能，规范作业流程，有效提升作业效率、产品质量和管理能力。 ")]),_c('TopNav',{attrs:{"list":_vm.nav}}),_c('ViewMain',{attrs:{"title":"MR三维互动教学系统","theme":"gray","id":_vm.getNavHash('MR三维互动教学系统')}},[_c('ViewContent',{attrs:{"title":"产品介绍"}},[_vm._v(" 运用MR远程教学系统，打造易理解、多角度、易编辑、高质量、低成本的培训体验，开创新型培训模式。 "),_c('div',{staticStyle:{"margin-top":"40px","text-align":"center"}},[_c('img',{attrs:{"src":require("@/assets/product/craftTraining/pic-1.png"),"alt":""}})])]),_c('ViewContent',{attrs:{"title":"产品功能"}},[_vm._v(" 眼球追踪、手动跟踪、语音控制、全息影像、虹膜识别、动作识别。 ")]),_c('ViewContent',{attrs:{"title":"产品价值"}},[_c('OrderedList',{attrs:{"list":[
          {
            content: '远程培训交互性高，体验真实，降低企业大量差旅费；',
          },
          {
            content: '产品的展示性好，互动操作易理解；',
          },
          {
            content: '减少实地培训开销，降低培训耗材。',
          },
        ]}})],1),_c('ViewContent',{attrs:{"title":"产品优势"}},[_c('IconCenterList',{attrs:{"list":[
          {
            icon: require('@/assets/product/craftTraining/icon-1-1.png'),
            title: '集现场+远程直播+手机端\n多场景的便捷培训',
          },
          {
            icon: require('@/assets/product/craftTraining/icon-1-2.png'),
            title: '自研低代码开发平台，\n实现多种复杂动作的一键生成',
          },
        ]}})],1)],1),_c('ViewMain',{attrs:{"title":"虚拟增强工艺培训系统","id":_vm.getNavHash('虚拟增强工艺培训系统')}},[_c('ViewContent',{attrs:{"title":"产品介绍"}},[_vm._v(" 通过虚拟培训场景，通过玩游戏通关的方式，实现工艺知识的培训和操作体验，同时在生产装配过程中，以动画形式展示电子工艺文件，指导装配。 "),_c('div',{staticStyle:{"margin-top":"40px","text-align":"center"}},[_c('img',{attrs:{"src":require("@/assets/product/craftTraining/pic-2.png"),"alt":""}})])]),_c('ViewContent',{attrs:{"title":"产品功能"}},[_vm._v(" 虚拟操作培训、实时作业指导、装配作业防错。 ")]),_c('ViewContent',{attrs:{"title":"产品价值"}},[_c('OrderedList',{attrs:{"list":[
          {
            content: '寓教于乐，培训效果好，有专项测评；',
          },
          {
            content: '沉浸式虚拟场景培训，让员工技能水平，逐级提高；',
          },
          {
            content: '与MES集成，实现动态显示工位的操作指导艺，实时指导。',
          },
        ]}})],1)],1),_c('ViewMain',{attrs:{"title":"赋能培训课程","theme":"gray","id":_vm.getNavHash('赋能培训课程')}},[_c('ViewContent',{attrs:{"title":"产品介绍"}},[_vm._v(" 助力企业在工业互联网时代实现数字化转型，建立竞争优势，包括降本增效、提高生产效率，减少人力成本、加速产品迭代，提升对智能制造的理解等。 "),_c('div',{staticStyle:{"margin-top":"40px","text-align":"center"}},[_c('img',{attrs:{"src":require("@/assets/product/craftTraining/pic-3.png"),"alt":""}})])]),_c('ViewContent',{attrs:{"title":"产品类型"}},[_vm._v(" 精品课程、参观访学、企业定制。 ")]),_c('ViewContent',{attrs:{"title":"产品参考类型"}},[_vm._v(" 《智能制造·数字化转型》 "),_c('br'),_vm._v(" 《走进灯塔工程》 "),_c('br'),_vm._v(" 《理解工业互联网》 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }