var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('TopBanner',{attrs:{"title":"Ｇ－iMOM数字化运营","bgImg":require('@/assets/product/ImomDigitalOperation/banner-bg.png')}},[_vm._v(" 用端到端的数字化集成技术，去除一切中间环节，实现C2M+OTD的自适应运营；满足：多品种组合生产自适应、订单计划变更自适应、生产过程波动自适应。 ")]),_c('TopNav',{attrs:{"list":_vm.nav}}),_c('ViewMain',{attrs:{"title":"PMS 自适应主数据管理","theme":"gray","id":_vm.getNavHash('PMS自适应主数据管理')}},[_c('ViewContent',{attrs:{"title":"产品介绍"}},[_vm._v(" 基于一套主数据贯通研、产、供、销、服全业务价值链数据流，通过“数据破壁”实现企业内部信息的统一。 "),_c('div',{staticStyle:{"text-align":"center","margin-top":"40px"}},[_c('img',{attrs:{"src":require("@/assets/product/ImomDigitalOperation/pic-1.png"),"alt":""}})])]),_c('ViewContent',{attrs:{"title":"产品价值"}},[_c('OrderedList',{attrs:{"list":[
          {
            content: 'PMS支持由订单驱动的研产供销全流程的业务数据自动生成，且全流程可追溯，无需人工干预，最大限度降低管理内耗；',
          },
          {
            content: 'PMS支持由订单驱动的研产供销全流程的业务数据即时生成，满足个性化定制条件下无法预设BOM数据的管理要求；',
          },
          {
            content: 'PMS对企业信息化程度零要求、流程零改造、使用零门槛，系统极轻极简，具备乐高式接口，可快速安装、快速使用；',
          },
          {
            content: 'PMS对核心业务规则进行函数式封装，企业运营管理人员仅关注输入即可得输出。',
          },
        ]}})],1)],1),_c('ViewMain',{attrs:{"title":"JIS 自适应生产管理","id":_vm.getNavHash('JIS自适应生产管理')}},[_c('ViewContent',{attrs:{"title":"产品介绍"}},[_vm._v(" 通过产线平衡计算及数字孪生实时仿真，实现小规模多品种生产模式下的工位级精确排产及JIT生产时序指令自动生成。 "),_c('div',{staticStyle:{"text-align":"center","margin-top":"40px"}},[_c('img',{attrs:{"src":require("@/assets/product/ImomDigitalOperation/pic-2.png"),"alt":""}})])]),_c('ViewContent',{attrs:{"title":"产品价值"}},[_c('OrderedList',{attrs:{"list":[
          {
            content: '动态APS排产：JIS通过对物料MRD及产线资源的平衡计算，实现小规模多品种生产模式下的工艺单元级精确排产；',
          },
          {
            content: '指令驱动执行：JIS联动数字孪生仿真技术，7分钟即可完成生产过程模拟仿真，输出JIT时序指令拉动生产执行，实现生产过程零等待浪费；',
          },
          {
            content: 'IOT智能物联执行器：轻量化传统MES功能，通过执行指令语义标准化、 IOT接口标准化，实现信息流与执行操作的自动联结。'
          }
        ]}})],1)],1),_c('ViewMain',{attrs:{"title":"LES 自适应物流管理","theme":"gray","id":_vm.getNavHash('LES自适应物流管理')}},[_c('ViewContent',{attrs:{"title":"产品介绍"}},[_vm._v(" 基于供应链全域透明实现深度协同，基于物料MRD零风险的前提确保物流成本最优&响应最快。 "),_c('div',{staticStyle:{"text-align":"center","margin-top":"40px"}},[_c('img',{attrs:{"src":require("@/assets/product/ImomDigitalOperation/pic-3.png"),"alt":""}})])]),_c('ViewContent',{attrs:{"title":"产品价值"}},[_c('OrderedList',{attrs:{"list":[
          {
            content: '分布式仓储管理库存信息集中管理，根据拉动规则生成物料需求调度指令；采用仿真及算法对库存进行优化，保障全链库存最低且分布最优；',
          },
          {
            content: '基于包装标准化装箱算法、社会运力集约式运输，实现C2M模式下小批量多频次的物料需求MRD无风险且物流成本最优；',
          },
          {
            content: '六大策略实现敏捷的、全局逆向物流管理能力，保障订单变更场景下供应链成本最低，且不影响OTD交期。',
          },
        ]}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }