export default [
  {
    name: 'MR三维互动教学系统',
    hash: 'vcOne',
  },
  {
    name: '虚拟增强工艺培训系统',
    hash: 'vcTwo',
  },
  {
    name: '赋能培训课程',
    hash: 'vcThree',
  },
]
