var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('TopBanner',{attrs:{"title":"机器人软件","bgImg":require('@/assets/product/robotSoftware/banner-bg.png')}},[_vm._v(" 工业机器人正不断替代人力，进入工厂，成为生产工作的“主角”，围绕其操作使用、维护提效的探索，一些机器人软件，能够发挥极大价值。 ")]),_c('TopNav',{attrs:{"list":_vm.nav}}),_c('ViewMain',{attrs:{"title":"G-iCA机器人坐标系标定软件","theme":"gray","id":_vm.getNavHash('G-iCA机器人坐标系标定软件')}},[_c('ViewContent',{attrs:{"title":"产品介绍"}},[_vm._v(" G-iCA是一款国产自主开发的机器人坐标标定产品，能决实际工厂现场安装偏差问题。通过实际工厂与虚拟工厂的坐标偏差测算，拟合出最优的机器人参考坐标系，保证机器人离线程序顺利实施，尤其适用于柔性生产线，设备初次安装。 "),_c('div',{staticStyle:{"background":"#fff","text-align":"center","font-size":"0","padding":"24px 0","margin-top":"40px"}},[_c('img',{attrs:{"src":require("@/assets/product/robotSoftware/pic-1.png"),"alt":""}})])]),_c('ViewContent',{attrs:{"title":"产品功能"}},[_vm._v(" 针对ABB和KUKA机器人进行相关坐标系标定。 ")]),_c('ViewContent',{attrs:{"title":"产品价值"}},[_c('OrderedList',{attrs:{"list":[
          {
            content: '节约机器人现场调试时间20%-50%时间，提高投产效率；',
          },
          {
            content: '减少因坐标系错误引起的产品质量问题。',
          },
        ]}})],1),_c('ViewContent',{attrs:{"title":"产品优势"}},[_vm._v(" 基于PMP方法论和吉利10年有效实践 "),_c('IconCenterList',{staticStyle:{"margin-top":"40px"},attrs:{"list":[
          {
            icon: require('@/assets/product/robotSoftware/icon-1-1.png'),
            title: '支持定制化开发',
          },
          {
            icon: require('@/assets/product/robotSoftware/icon-1-2.png'),
            title: '测算精度高，在同样条件下数据点平均误差为0.17mm',
          },
          {
            icon: require('@/assets/product/robotSoftware/icon-1-3.png'),
            title: '性价比高，使用成本更低',
          },
        ]}})],1)],1),_c('ViewMain',{attrs:{"title":"G-iRP机器人软件扩展功能包","id":_vm.getNavHash('G-iRP机器人软件扩展功能包')}},[_c('ViewContent',{attrs:{"title":"产品介绍"}},[_vm._v(" 基于KUKA、ABB机器人而做的深度定制化功能软件，实现标准化作业程序，降低使用机器人的门槛，提升生产效率。 "),_c('IconLeftList',{staticStyle:{"margin-top":"40px"},attrs:{"list":[
          {
            icon: require('@/assets/product/robotSoftware/icon-2-1.png'),
            title: '数据采集功能包',
            desc: '高频数据采集，间隔低至20ms',
          },
          {
            icon: require('@/assets/product/robotSoftware/icon-2-2.png'),
            title: '权限管控功能包',
            desc: 'U盘授权，一盘一码，即插即用',
          },
          {
            icon: require('@/assets/product/robotSoftware/icon-2-3.png'),
            title: '数据存储功能包',
            desc: '长周期的文件，日志，数据存储',
          },
          {
            icon: require('@/assets/product/robotSoftware/icon-2-4.png'),
            title: '碰撞识别功能包',
            desc: '降低碰撞造成的损失',
          },
        ]}})],1),_c('ViewContent',{attrs:{"title":"产品功能"}},[_vm._v(" 使机器人快速实现特定功能。 ")]),_c('ViewContent',{attrs:{"title":"产品价值"}},[_c('OrderedList',{attrs:{"list":[
          {
            content: '节约自行开发时间，提高投产效率；',
          },
          {
            content: '辅助具体机器人作业执行，降低损失。',
          },
        ]}})],1)],1),_c('ViewMain',{attrs:{"title":"G-iRP机器人软件扩展工艺包","theme":"gray","id":_vm.getNavHash('G-iRP机器人软件扩展工艺包')}},[_c('ViewContent',{attrs:{"title":"产品介绍"}},[_vm._v(" 基于KUKA、ABB机器人而做的深度定制化工艺软件，实现标准化作业程序，降低使用机器人的门槛，提升生产效率。 "),_c('IconLeftList',{staticStyle:{"margin-top":"40px"},attrs:{"list":[
          {
            icon: require('@/assets/product/robotSoftware/icon-3-1.png'),
            title: '激光切割工艺包',
            desc: '自动规划切圆轨迹',
          },
          {
            icon: require('@/assets/product/robotSoftware/icon-3-2.png'),
            title: '滚边工艺包',
            desc: '批量轨迹偏移，无需反复示教',
          },
          {
            icon: require('@/assets/product/robotSoftware/icon-3-3.png'),
            title: '流钻工艺包',
            desc: '工艺时序控制集成，降低使用难度',
          },
          {
            icon: require('@/assets/product/robotSoftware/icon-3-4.png'),
            title: '铆钉摩擦焊工艺包',
            desc: '工艺时序控制集成，降低使用难度',
          },
        ]}})],1),_c('ViewContent',{attrs:{"title":"产品功能"}},[_vm._v(" 满足特定工艺所需，快速符合特定工艺应用要求。 ")]),_c('ViewContent',{attrs:{"title":"产品价值"}},[_c('OrderedList',{attrs:{"list":[
          {
            content: '节约自行开发时间，提高投产效率；',
          },
          {
            content: '辅助具体机器人作业执行；',
          },
          {
            content: '可针对客户需求定制开发其他工艺包。',
          },
        ]}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }