<template>
  <div>
    <TopBanner
      title="AI应用"
      :bgImg="require('@/assets/product/AIApplication/banner-bg.png')"
    >
      在离散制造业中，产品非标准化程度高，经验依赖性强，且传统的垂直制造体系使得不同业务环节的子系统彼此孤立，无法满足工业企业决策优化、快速相应等方面的功能和性能的诉求。因此，AI技术成为了一个“破局”的关键。打通数据孤岛之间的链路，通过大数据和AI对工业数据进行分析建模，沉淀工业知识和经验，在原有业务逻辑基础上形成AI应用，如节能降耗、设备预测性维护、工艺优化、AI质检等，以此实现企业业务优化和创新，提升工业数据价值。
    </TopBanner>
    <TopNav :list="nav" />
    <ViewMain title="工业视觉AI" theme="gray" :id="getNavHash('工业视觉AI')">
      <ViewContent title="产品介绍">
        基于2D/3D视觉识别分析技术积累，可有效解决工业产品质检、分类、缺陷检测等多种任务，赋予工业设备“眼睛”和AI的“大脑”。
        <div style="margin-top: 40px; text-align: center;">
          <img src="@/assets/product/AIApplication/pic-1.png" alt="" />
        </div>
      </ViewContent>
      <ViewContent title="使用场景">
        ·表面缺陷检测：如整车车身、电路板等； <br />
        ·行为智能识别：如园区吸烟检测、车间工具在位检测等； <br />
        ·编码自动校验：如汽车轮毂钢印、物料装箱等； <br />
        ·料量自动监测：如皮带运行料量、注塑机、废料下料口等。
      </ViewContent>
      <ViewContent title="解决方案案例">
        机器人视觉识别抓取分拣：使用KUKA机械臂和2D相机完成了大型机械臂对汽车零件的定位、识别、抓取和分类任务。
      </ViewContent>
      <ViewContent title="产品价值">
        <OrderedList
          :list="[
            {
              content: '抓取分类效率高；',
            },
            {
              content: '降低劳动力成本。',
            },
          ]"
        />
      </ViewContent>
    </ViewMain>
    <!--  -->
    <ViewMain title="工业数据AI" :id="getNavHash('工业数据AI')">
      <ViewContent title="产品介绍">
        面向工业应用，利用大数据和AI技术，对工业数据进行处理和分析，挖掘数据价值，沉淀工业知识，实现业务优化和创新。
        <div style="margin-top: 40px; text-align: center;">
          <img src="@/assets/product/AIApplication/pic-2.png" alt="" />
        </div>
      </ViewContent>
      <ViewContent title="使用场景">
        ·工艺优化； <br />
        ·节能降耗； <br />
        ·质量预测； <br />
        ·设备预测性维护。
      </ViewContent>
      <ViewContent title="解决方案案例">
        尺寸分析预警系统：尺寸分析预警系统对尺寸设计和测量数据进行智能分析，辅助尺寸设计，提供尺寸质量评估、问题实时预警和诊断，提升尺寸合格率和产品质量。
      </ViewContent>
      <ViewContent title="产品价值">
        <OrderedList
          :list="[
            {
              content: '提升尺寸设计效率30%；',
            },
            {
              content: '降低尺寸质量问题50%；',
            },
            {
              content: '尺寸问题快速归因并解决；',
            },
            {
              content: '缩短尺寸问题解决时间70%。',
            },
          ]"
        />
      </ViewContent>
    </ViewMain>
  </div>
</template>

<script>
import ViewMain from '@/components/ViewMain'
import ViewContent from '@/components/ViewContent'
import TopBanner from '@/components/TopBanner'
import TopNav from '@/components/TopNav'
import OrderedList from '@/components/OrderedList'
import mixin from '@/views/product/mixin'
import nav from './nav'

export default {
  components: {
    ViewMain,
    ViewContent,
    TopBanner,
    TopNav,
    OrderedList,
  },
  mixins: [mixin],
  data () {
    return {
      nav,
    }
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
