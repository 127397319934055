export default [
  {
    name: 'G-iCA机器人坐标系标定软件',
    hash: 'vcOne',
  },
  {
    name: 'G-iRP机器人软件扩展功能包',
    hash: 'vcTwo',
  },
  {
    name: 'G-iRP机器人软件扩展工艺包',
    hash: 'vcThree',
  },
]
