export default [
  {
    name: 'G-iPS工业数字孪生软件',
    hash: 'vcOne',
  },
  {
    name: '数字化工艺仿真',
    hash: 'vcTwo',
  },
  {
    name: '生产虚拟调试',
    hash: 'vcThree',
  },
  {
    name: '虚拟生产验证',
    hash: 'vcFour',
  },
]
