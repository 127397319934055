export default [
  {
    name: 'G-iWCS智能仓储控制系统',
    hash: 'vcOne',
  },
  {
    name: 'G-iEAM设备管理系统',
    hash: 'vcTwo',
  },
  {
    name: 'G-iEMS能耗管理系统',
    hash: 'vcThree',
  },
  {
    name: 'G-iLIMS实验室管理系统',
    hash: 'vcFour',
  },
  {
    name: 'G-iAGV 通用AGV调度系统',
    hash: 'vcFive',
  },
]
