<template>
  <div>
    <TopBanner
      title="虚拟工厂"
      :bgImg="require('@/assets/product/virtualFactory/banner-bg.png')"
    >
      利用数字孪生技术，为企业的生产基地创建“虚拟工厂”。可通过AI机器学习算法或模拟，用动态模型、数字表述设备、流程和产品流特性、物料清单和操作规则等对每个生产基地进行“假设”分析。如企业要开发新产品、调整定价策略，可以利用虚拟工厂评估生产是否能调整匹配新需求，以实现新策略
    </TopBanner>
    <TopNav :list="nav" />
    <ViewMain
      title="G-iPS工业数字孪生软件"
      theme="gray"
      :id="getNavHash('G-iPS工业数字孪生软件')"
    >
      <ViewContent title="产品介绍">
        G-iPS是西图盟团队自研的一款可作为PD/PS替代方案的全国产化工业智能制造3D数字孪生软件平台。
        <div style="text-align: center; margin-top: 40px;">
          <img src="@/assets/product/virtualFactory/pic-1.png" alt="" />
        </div>
      </ViewContent>
      <ViewContent title="产品功能">
        制造流程规划、工艺规划、工艺协同、工程分析、制造仿真、虚拟调试、人机交互分析等。
      </ViewContent>
      <ViewContent title="产品价值">
        <OrderedList
          :list="[
            {
              content: '通过同步执行产品设计和制造工程，缩短产品推出周期；',
            },
            {
              content: '提前预知，可提前规划混线生产场景设施的布局；',
            },
            {
              content:
                '提前验证，在规划流程中验证设计参数，避免工厂设计上的问题。',
            },
          ]"
        />
      </ViewContent>
      <ViewContent title="产品优势">
        基于PMP方法论和吉利10年有效实践。
        <IconCenterList
          style="margin-top: 40px;"
          :list="[
            {
              icon: require('@/assets/product/virtualFactory/icon-1-1.png'),
              title: '快速上手，使用门槛低',
            },
            {
              icon: require('@/assets/product/virtualFactory/icon-1-2.png'),
              title: '开放的接口，支持用户定制开发',
            },
            {
              icon: require('@/assets/product/virtualFactory/icon-1-3.png'),
              title: '同性能终端，可使用更多单元组件设备',
            },
          ]"
        />
      </ViewContent>
    </ViewMain>
    <!--  -->
    <ViewMain title="数字化工艺仿真" :id="getNavHash('数字化工艺仿真')">
      <ViewContent title="产品介绍">
        数字化工艺仿真主要是在工厂投产前，通过数字建模对产线工艺布局、工艺规划、工艺方案等进行详细的可行性验证分析。

        <div style="text-align: center; margin-top: 40px;">
          <img src="@/assets/product/virtualFactory/pic-2.png" alt="" />
        </div>
      </ViewContent>
      <ViewContent title="产品功能">
        通过对生产节拍、产能、载荷、机器人运动状态等进行仿真评估，降低规划与设计过程的不确定性，根据对初期方案验证及优化，为企业提供最优的工艺解决方案。
      </ViewContent>
      <ViewContent title="产品价值">
        <OrderedList
          :list="[
            {
              content: '缩短工厂投产时间；',
            },
            {
              content: '提供最优工艺解决方案产品优势。',
            },
          ]"
        />
      </ViewContent>
    </ViewMain>
    <!--  -->
    <ViewMain
      title="生产虚拟调试"
      theme="gray"
      :id="getNavHash('生产虚拟调试')"
    >
      <ViewContent title="产品介绍">
        生产虚拟调试，是在数字孪生基础建模基础上，将对整条生产线的机械、电气和机器人三大系统整合在一起进行模拟验证。
        <div style="text-align: center; margin-top: 40px;">
          <img src="@/assets/product/virtualFactory/pic-3.png" alt="" />
        </div>
      </ViewContent>
      <ViewContent title="产品功能">
        通过虚拟调试，能极大提高企业对新生产系统整体运转时可能发生问题的认知度，从而在未投入正式制造之前对PLC、机器人等离线程序进行验证和优化，修补漏洞，最终向现场输出经过严格验证的生产程序，达到进一步地缩短产品推出周期，降低工程成本和风险的目的。
      </ViewContent>
      <ViewContent title="产品价值">
        <OrderedList
          :list="[
            {
              content: '提前验证，减少对实际设备的依赖；',
            },
            {
              content: '避免设备损坏；',
            },
            {
              content: '缩短现场调试时间；',
            },
            {
              content: '更高的项目数据交付质量；',
            },
            {
              content: '降低项目成本。',
            },
          ]"
        />
      </ViewContent>
    </ViewMain>
    <!--  -->
    <ViewMain title="虚拟生产验证" :id="getNavHash('虚拟生产验证')">
      <ViewContent title="产品介绍">
        在订单驱动的个性化定制背景下，为了满足柔性、快响应的生产模式，虚拟工厂接收到系统下达的生产订单后，需先拆分订单，将对应生产计划利用数字孪生技术进行虚拟验证。

        <div style="text-align: center; margin-top: 40px;">
          <img src="@/assets/product/virtualFactory/pic-4.png" alt="" />
        </div>
      </ViewContent>
      <ViewContent title="产品功能">
        通过反复验证修改，将同时满足客户需求和生产节拍的生产计划下发至工厂进行生产。通过这种“从订单到生产”全流程验证的虚拟试生产模式，可极大减少个性化定制的生产准备时间，确保最终产品实现零风险准时交付。
      </ViewContent>
      <ViewContent title="产品价值">
        <OrderedList
          :list="[
            {
              content: '降低产品生产切换时间；',
            },
            {
              content: '满足个性化订单准时交付；',
            },
            {
              content: '提前识别工厂产线运行风险。',
            },
          ]"
        />
      </ViewContent>
    </ViewMain>
  </div>
</template>

<script>
import ViewMain from '@/components/ViewMain'
import ViewContent from '@/components/ViewContent'
import TopBanner from '@/components/TopBanner'
import TopNav from '@/components/TopNav'
import OrderedList from '@/components/OrderedList'
import IconCenterList from '@/components/IconCenterList'
import mixin from '@/views/product/mixin'
import nav from './nav'

export default {
  components: {
    ViewMain,
    ViewContent,
    TopBanner,
    TopNav,
    OrderedList,
    IconCenterList,
  },
  mixins: [mixin],
  data () {
    return {
      nav,
    }
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
