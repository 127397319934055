<template>
  <div>
    <TopBanner
      title="过程管控"
      :bgImg="require('@/assets/product/processControl/banner-bg.png')"
    >
      采用云、AR、AI、IOT、边缘计算等先进技术，对流程工艺的关键要素开发出辅助过程管控的各项工程服务工具。
    </TopBanner>
    <TopNav :list="nav" />
    <ViewMain
      title="软安灯呼叫系统"
      theme="gray"
      :id="getNavHash('软安灯呼叫系统')"
    >
      <ViewContent title="产品介绍">
        生产线出现生产、设备、质量等问题，扫描现场二维码，现场声光报警提示，同时系统自动记录报警及异常原因，ANDON屏同步显示，将报警按类定位到相关人员（智能终端、移动端推送）。
        <div style="margin-top: 40px; text-align: center;">
          <img src="@/assets/product/processControl/pic-1.png" alt="" />
        </div>
      </ViewContent>
      <ViewContent title="产品功能">
        发起安灯、响应安灯、关闭安灯、KPI指标分析等。
      </ViewContent>
      <ViewContent title="产品价值">
        <OrderedList
          :list="[
            {
              content: '直接扫描二维码，无需安装安灯设备；',
            },
            {
              content: '帮助业务人员快速定位并响应问题及事后分析改善。',
            },
          ]"
        />
      </ViewContent>
    </ViewMain>
    <!--  -->
    <ViewMain title="精益提案管理系统" :id="getNavHash('精益提案管理系统')">
      <ViewContent title="产品介绍">
        以精益管理思想为指导，将合理化提案、项目管理、改善评价、奖励分发等改善流程电子化，实现全员持续改善，是精益改善文化落地利器。
      </ViewContent>
      <ViewContent title="产品功能">
        现场问题记录、问题知识管理、问题报表、生成创意排行、问题奖励兑现。
      </ViewContent>
      <ViewContent title="产品价值">
        <OrderedList
          :list="[
            {
              content: '激发员工潜能，提高员工解决问题能力；',
            },
            {
              content: '有助于发掘培育一线员工中的隐藏人才；',
            },
            {
              content: '帮助构建全员改善的精益企业文化。',
            },
          ]"
        />
      </ViewContent>
    </ViewMain>
    <!--  -->
    <ViewMain
      title="AR质量防错眼镜"
      theme="gray"
      :id="getNavHash('AR质量防错眼镜')"
    >
      <ViewContent title="产品介绍">
        通过AR智能眼镜，实现第一视角过程记录、推送标准作业流程和资产数据、连接IoT设备、模型图纸近眼显示、关键步骤AI嵌入复查确认，在提高作业效率的同时真正做到作业全程可追溯。
        <div style="margin-top: 40px; text-align: center;">
          <img src="@/assets/product/processControl/pic-2.png" alt="" />
        </div>
      </ViewContent>
      <ViewContent title="产品功能">
        作业工序展示、语音播报、录像、生产数据分析（包括：节拍统计、线平衡分析、作业效率等）。
      </ViewContent>
      <ViewContent title="产品价值">
        <OrderedList
          :list="[
            {
              content: '规范作业流程：新员工培训上岗时间从1个礼拜降低至1天；',
            },
            {
              content:
                '提升产品质量：100%车型信息匹配率，减少零件错装率>98%的错误识别率。错、漏装问题下降57%；',
            },
            {
              content:
                '提升作业效率：毫秒级（0.033 秒）识别速度，提高检验工序效率50%以上；',
            },
            {
              content:
                '提升管理效率：聚焦质量异常点，为质量改进提供数据支持作业流程标准化，防呆滞、防错提供有效保障。',
            },
          ]"
        />
      </ViewContent>
    </ViewMain>
    <!--  -->
    <ViewMain title="工艺诊断专家系统" :id="getNavHash('工艺诊断专家系统')">
      <ViewContent title="产品介绍">
        基于工艺的知识图谱技术，建立设备工艺“指导手册”，可以根据工艺问题现象，自动推理排查有可能的原因和解决方案。

        <div style="margin-top: 40px; text-align: center;">
          <img src="@/assets/product/processControl/pic-3.png" alt="" />
        </div>
      </ViewContent>
      <ViewContent title="产品功能">
        故障诊断、措施推荐、故障图谱。
      </ViewContent>
      <ViewContent title="产品价值">
        <OrderedList
          :list="[
            {
              content: '提升客户问题解决速度，服务满意度；',
            },
            {
              content: '故障诊断率＞85%；',
            },
            {
              content: '故障排查时间秒级；',
            },
            {
              content: '节省故障排产人员数量与时间。',
            },
          ]"
        />
      </ViewContent>
    </ViewMain>
  </div>
</template>

<script>
import ViewMain from '@/components/ViewMain'
import ViewContent from '@/components/ViewContent'
import TopBanner from '@/components/TopBanner'
import TopNav from '@/components/TopNav'
import OrderedList from '@/components/OrderedList'
import mixin from '@/views/product/mixin'
import nav from './nav'

export default {
  components: {
    ViewMain,
    ViewContent,
    TopBanner,
    TopNav,
    OrderedList,
  },
  mixins: [mixin],
  data () {
    return {
      nav,
    }
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
