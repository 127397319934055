<template>
  <div>
    <TopBanner
      title="机器人软件"
      :bgImg="require('@/assets/product/robotSoftware/banner-bg.png')"
    >
      工业机器人正不断替代人力，进入工厂，成为生产工作的“主角”，围绕其操作使用、维护提效的探索，一些机器人软件，能够发挥极大价值。
    </TopBanner>
    <TopNav :list="nav" />
    <ViewMain
      title="G-iCA机器人坐标系标定软件"
      theme="gray"
      :id="getNavHash('G-iCA机器人坐标系标定软件')"
    >
      <ViewContent title="产品介绍">
        G-iCA是一款国产自主开发的机器人坐标标定产品，能决实际工厂现场安装偏差问题。通过实际工厂与虚拟工厂的坐标偏差测算，拟合出最优的机器人参考坐标系，保证机器人离线程序顺利实施，尤其适用于柔性生产线，设备初次安装。

        <div
          style="background: #fff; text-align: center; font-size: 0; padding: 24px 0; margin-top: 40px;"
        >
          <img src="@/assets/product/robotSoftware/pic-1.png" alt="" />
        </div>
      </ViewContent>
      <ViewContent title="产品功能">
        针对ABB和KUKA机器人进行相关坐标系标定。
      </ViewContent>
      <ViewContent title="产品价值">
        <OrderedList
          :list="[
            {
              content: '节约机器人现场调试时间20%-50%时间，提高投产效率；',
            },
            {
              content: '减少因坐标系错误引起的产品质量问题。',
            },
          ]"
        />
      </ViewContent>
      <ViewContent title="产品优势">
        基于PMP方法论和吉利10年有效实践
        <IconCenterList
          style="margin-top: 40px;"
          :list="[
            {
              icon: require('@/assets/product/robotSoftware/icon-1-1.png'),
              title: '支持定制化开发',
            },
            {
              icon: require('@/assets/product/robotSoftware/icon-1-2.png'),
              title: '测算精度高，在同样条件下数据点平均误差为0.17mm',
            },
            {
              icon: require('@/assets/product/robotSoftware/icon-1-3.png'),
              title: '性价比高，使用成本更低',
            },
          ]"
        />
      </ViewContent>
    </ViewMain>
    <!--  -->
    <ViewMain
      title="G-iRP机器人软件扩展功能包"
      :id="getNavHash('G-iRP机器人软件扩展功能包')"
    >
      <ViewContent title="产品介绍">
        基于KUKA、ABB机器人而做的深度定制化功能软件，实现标准化作业程序，降低使用机器人的门槛，提升生产效率。

        <IconLeftList
          style="margin-top: 40px;"
          :list="[
            {
              icon: require('@/assets/product/robotSoftware/icon-2-1.png'),
              title: '数据采集功能包',
              desc: '高频数据采集，间隔低至20ms',
            },
            {
              icon: require('@/assets/product/robotSoftware/icon-2-2.png'),
              title: '权限管控功能包',
              desc: 'U盘授权，一盘一码，即插即用',
            },
            {
              icon: require('@/assets/product/robotSoftware/icon-2-3.png'),
              title: '数据存储功能包',
              desc: '长周期的文件，日志，数据存储',
            },
            {
              icon: require('@/assets/product/robotSoftware/icon-2-4.png'),
              title: '碰撞识别功能包',
              desc: '降低碰撞造成的损失',
            },
          ]"
        />
      </ViewContent>
      <ViewContent title="产品功能">
        使机器人快速实现特定功能。
      </ViewContent>
      <ViewContent title="产品价值">
        <OrderedList
          :list="[
            {
              content: '节约自行开发时间，提高投产效率；',
            },
            {
              content: '辅助具体机器人作业执行，降低损失。',
            },
          ]"
        />
      </ViewContent>
    </ViewMain>
    <!--  -->
    <ViewMain
      title="G-iRP机器人软件扩展工艺包"
      theme="gray"
      :id="getNavHash('G-iRP机器人软件扩展工艺包')"
    >
      <ViewContent title="产品介绍">
        基于KUKA、ABB机器人而做的深度定制化工艺软件，实现标准化作业程序，降低使用机器人的门槛，提升生产效率。
        <IconLeftList
          style="margin-top: 40px;"
          :list="[
            {
              icon: require('@/assets/product/robotSoftware/icon-3-1.png'),
              title: '激光切割工艺包',
              desc: '自动规划切圆轨迹',
            },
            {
              icon: require('@/assets/product/robotSoftware/icon-3-2.png'),
              title: '滚边工艺包',
              desc: '批量轨迹偏移，无需反复示教',
            },
            {
              icon: require('@/assets/product/robotSoftware/icon-3-3.png'),
              title: '流钻工艺包',
              desc: '工艺时序控制集成，降低使用难度',
            },
            {
              icon: require('@/assets/product/robotSoftware/icon-3-4.png'),
              title: '铆钉摩擦焊工艺包',
              desc: '工艺时序控制集成，降低使用难度',
            },
          ]"
        />
      </ViewContent>
      <ViewContent title="产品功能">
        满足特定工艺所需，快速符合特定工艺应用要求。
      </ViewContent>
      <ViewContent title="产品价值">
        <OrderedList
          :list="[
            {
              content: '节约自行开发时间，提高投产效率；',
            },
            {
              content: '辅助具体机器人作业执行；',
            },
            {
              content: '可针对客户需求定制开发其他工艺包。',
            },
          ]"
        />
      </ViewContent>
    </ViewMain>
  </div>
</template>

<script>
import ViewMain from '@/components/ViewMain'
import ViewContent from '@/components/ViewContent'
import TopBanner from '@/components/TopBanner'
import TopNav from '@/components/TopNav'
import OrderedList from '@/components/OrderedList'
import IconCenterList from '@/components/IconCenterList'
import IconLeftList from '@/components/IconLeftList'
import mixin from '@/views/product/mixin'
import nav from './nav'

export default {
  components: {
    ViewMain,
    ViewContent,
    TopBanner,
    TopNav,
    OrderedList,
    IconCenterList,
    IconLeftList,
  },
  mixins: [mixin],
  data () {
    return {
      nav,
    }
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
